import { Elm } from "../elm/Main";

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// state

const state = { packlists: [] };

// helpers

const diff = (old, updated) => {
  return {
    removed: old.filter(o => updated.indexOf(o) === -1),
    added: updated.filter(u => old.indexOf(u) === -1),
    same: updated.filter(u => old.indexOf(u) !== -1)
  };
};

const success = action => success => app.ports.fromJs.send({ action, success });

const successWithLog = action => success => {
  console.log(action, success);
  app.ports.fromJs.send({ action, success });
};

const error = action => error => {
  console.error(action, success);
  app.ports.fromJs.send({ action, error });
};

const addEvent = (packlistId, fn) => {
  const ref = firebase
    .database()
    .ref()
    .child(`packlists/${packlistId}/events`)
    .push();

  const uid = firebase.auth().currentUser.uid;

  const key = ref.key;

  const event = fn(key);

  ref.set(createEvent(key, event));
};

const createEvent = (key, data) => ({
  key,
  uid: firebase.auth().currentUser.uid,
  timestamp: new Date().getTime(),
  ...data
});

// init firebase

const firebaseConfig = {
  apiKey: "AIzaSyD5-5FDAZv-FooGKxxHD6BpoWvfV3UKSJU",
  authDomain: "packlist-eacf8.firebaseapp.com",
  databaseURL: "https://packlist-eacf8.firebaseio.com",
  projectId: "packlist-eacf8",
  storageBucket: "packlist-eacf8.appspot.com",
  messagingSenderId: "520006471962",
  appId: "1:520006471962:web:fc5a6bbde9c5e58d441ca3",
  measurementId: "G-3MDJH4H2GH"
};

firebase.initializeApp(firebaseConfig);

// init auth
const provider = new firebase.auth.GoogleAuthProvider();
//provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
provider.addScope("https://www.googleapis.com/auth/userinfo.email");

// init elm

const app = Elm.Main.init({
  node: document.getElementById("root"),
  flags: {
    hue: Math.floor(Math.random() * 360),
    width: window.innerWidth || document.body.clientWidth,
    now: Date.now()
  }
});

app.ports.toJs.subscribe(e => {
  switch (e.action) {
    case "LOGIN":
      return login();
    case "LOGOUT":
      return logout();
    case "ADD_PACKLIST":
      return addPacklist(e);
    case "RENAME_PACKLIST":
      return renamePacklist(e);
    case "ADD_PACKITEM":
      return addPackitem(e);
    case "RENAME_PACKITEM":
      return renamePackitem(e);
    case "INVITE_COLLABORATOR":
      return inviteCollaborator(e);
    case "REMOVE_PENDING_COLLABORATOR":
      return removePendingCollaborator(e);
    case "REMOVE_ACCEPTED_COLLABORATOR":
      return removeAcceptedCollaborator(e);
    case "COMPLETE_PACKITEM":
      return completePackitem(e);
    case "INCOMPLETE_PACKITEM":
      return incompletePackitem(e);
    case "ACCEPT_INVITE":
      return acceptInvite(e);
    case "DECLINE_INVITE":
      return declineInvite(e);
    case "FETCH_USER":
      return fetchUser(e);
    case "DELETE_PACKITEM":
      return deletePackitem(e);
    default:
      console.error("unknown action", e);
  }
});

firebase.auth().onAuthStateChanged(user => {
  if (user) {
    loginSuccess(user);
  } else {
    success("LOGOUT");
  }
});

// api

const login = () => {
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(res => loginSuccess(res.user))
    .catch(err => error("LOGIN")(err.message));
};

const loginSuccess = async user => {
  const { uid, email, emailVerified, photoURL, displayName } = firebase.auth().currentUser;

  success("LOGIN")({ uid, ...user });

  updateUser({
    uid,
    photoURL,
    displayName,
    email,
    email_base64: btoa(email)
  });

  firebase
    .database()
    .ref(`users/${uid}`)
    .on(`value`, async userSnapshot => {
      const userData = userSnapshot.val();
      if (userData) {
        if (emailVerified) {
          fetchPendingInvites(email);
        }
        const packlists = userData.packlists ? Object.keys(userData.packlists) : [];
        successWithLog("USER_DATA_FETCHED")({ ...userData, packlists });
        fetchPacklists(packlists);
      }
    });
};

const updateUser = user => {
  const ref = firebase.database().ref(`users/${user.uid}/details`);
  ref.set(user);
};

const fetchPendingInvites = email => {
  firebase
    .database()
    .ref(`pending/${btoa(email)}/packlists`)
    .on("value", s => {
      const val = s.val();
      const pending = val ? Object.values(val) : [];
      successWithLog("PENDING_INVITES_FETCHED")({
        pendingInvites: pending.filter(x => x)
      });
    });
};

const fetchPacklists = packlists => {
  const existing = state.packlists;

  if (!packlists.length) success("EVENTS_FETCHED")([]);

  const { added, removed } = diff(existing, packlists);

  added.forEach(packlistId => {
    const ref = firebase.database().ref(`packlists/${packlistId}/events`);
    ref.once("value", s => {
      const startKey = ref.push().key;
      const values = Object.values(s.val()).sort((a:any, b:any) => (a.timestamp > b.timestamp ? 1 : -1));

      success("EVENTS_FETCHED")(values);

      ref
        .orderByKey()
        .startAt(startKey)
        .on("child_added", s => {
          success("EVENTS_FETCHED")([s.val()]);
        });
    });
  });

  state.packlists = packlists;
};

const logout = () => console.log("logout");

const addPacklist = ({ name }) => {
  const uid = firebase.auth().currentUser.uid;

  const packlistId = firebase
    .database()
    .ref()
    .child("packlists")
    .push().key;

  const key = firebase
    .database()
    .ref()
    .child(`packlists/${packlistId}/events`)
    .push().key;

  const event = createEvent(key, {
    event: "PACKLIST_ADDED",
    packlistId,
    name
  });

  const updates = {
    [`packlists/${packlistId}/events/${key}`]: event,
    [`users/${uid}/packlists/${packlistId}`]: true
  };

  return firebase
    .database()
    .ref()
    .update(updates);
};

const inviteCollaborator = ({ packlistId, packlistName, email }) => {
  const { uid } = firebase.auth().currentUser;

  const key = firebase
    .database()
    .ref()
    .child(`packlists/${packlistId}/events`)
    .push().key;

  const event = createEvent(key, {
    event: "COLLABORATOR_INVITED",
    packlistId,
    email
  });

  const updates = {
    [`packlists/${packlistId}/events/${key}`]: event,
    [`pending/${btoa(email)}/packlists/${packlistId}`]: { packlistId, packlistName, uid }
  };

  return firebase
    .database()
    .ref()
    .update(updates);
};

const renamePacklist = ({ name, packlistId }) => {
  addEvent(packlistId, packitemId => ({
    event: "PACKLIST_RENAMED",
    packlistId,
    name
  }));
};

const addPackitem = ({ packlistId, name }) => {
  addEvent(packlistId, packitemId => ({
    event: "PACKITEM_ADDED",
    packitemId,
    packlistId,
    name
  }));
};

const renamePackitem = ({ packlistId, packitemId, name }) => {
  addEvent(packlistId, () => ({
    event: "PACKITEM_RENAMED",
    packitemId,
    packlistId,
    name
  }));
};

const deletePackitem = ({ packlistId, packitemId, name }) => {
  addEvent(packlistId, () => ({
    event: "PACKITEM_DELETED",
    packitemId,
    packlistId,
    name
  }));
};

const removePendingCollaborator = ({ packlistId, email }) => {
  addEvent(packlistId, () => ({
    event: "PENDING_COLLABORATOR_REMOVED",
    packlistId,
    email
  }));
};

const removeAcceptedCollaborator = ({ packlistId, uid }) => {
  addEvent(packlistId, () => ({
    event: "ACCEPTED_COLLABORATOR_REMOVED",
    packlistId,
    collaborator_uid: uid
  }));
};

const completePackitem = ({ packlistId, packitemId }) => {
  addEvent(packlistId, () => ({
    event: "PACKITEM_COMPLETED",
    packitemId,
    packlistId
  }));
};

const incompletePackitem = ({ packlistId, packitemId }) => {
  addEvent(packlistId, () => ({
    event: "PACKITEM_INCOMPLETED",
    packitemId,
    packlistId
  }));
};

const acceptInvite = ({ packlistId }) => {
  const { email, uid } = firebase.auth().currentUser;

  const key = firebase
    .database()
    .ref()
    .child(`packlists/${packlistId}/events`)
    .push().key;

  const event = createEvent(key, {
    event: "INVITE_ACCEPTED",
    packlistId,
    email,
    collaborator_uid: uid
  });

  const updates = {
    [`packlists/${packlistId}/events/${key}`]: event,
    [`pending/${btoa(email)}/packlists/${packlistId}`]: false,
    [`users/${uid}/packlists/${packlistId}`]: true
  };

  return firebase
    .database()
    .ref()
    .update(updates);
};

const declineInvite = ({ packlistId }) => {
  const { email } = firebase.auth().currentUser;

  const key = firebase
    .database()
    .ref()
    .child(`packlists/${packlistId}/events`)
    .push().key;

  const event = createEvent(key, {
    event: "INVITE_DECLINED",
    packlistId,
    email
  });

  const updates = {
    [`packlists/${packlistId}/events/${key}`]: event,
    [`pending/${btoa(email)}/packlists/${packlistId}`]: false
  };

  return firebase
    .database()
    .ref()
    .update(updates);
};

const fetchUser = ({ uid }) => {
  const ref = firebase.database().ref(`users/${uid}/details`);
  ref.once("value", s => {
    success("USER_FETCHED")(s.val());
  });
};
